import { createSlice } from '@reduxjs/toolkit';

const defaultTime = {
  influencer: 'This year',
  advertiser: 'All time',
};

const defaultStat = {
  influencer: 'Gigs completed',
  advertiser: 'Gigs bought',
};

export const leaderboardFiltersSlice = createSlice({
  name: 'leaderboardFilters',
  initialState: {
    networkId: null,
    time: defaultTime,
    stat: defaultStat,
    role: 'influencer',
  },

  reducers: {
    setNetworkIdFilter: (state, action) => {
      state.networkId = action.payload;
    },

    setTimeFilter: (state, action) => {
      const { role, value } = action.payload;

      state.time = {
        ...state.time,
        [role]: value,
      };
    },

    setStatFilter: (state, action) => {
      const { role, value } = action.payload;

      state.stat = {
        ...state.stat,
        [role]: value,
      };
    },

    setRoleFilter: (state, action) => {
      state.role = action.payload;

      state.time = defaultTime;
      state.stat = defaultStat;
    },
  },
});

export const {
  setNetworkIdFilter,
  setTimeFilter,
  setStatFilter,
  setRoleFilter,
} = leaderboardFiltersSlice.actions;

export default leaderboardFiltersSlice.reducer;
