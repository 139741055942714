import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  MenuItem,
  MenuList,
  Avatar,
  Divider,
  Popper,
  Grow,
  ClickAwayListener,
  Chip,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import clsx from 'clsx';

import { openModal } from 'features/utilsSlice';
import { postRequest } from 'utils/api';
import {
  getTokenFromResponse,
  identifyFullStoryUser,
  openCrispChat,
  setCrispUser,
} from 'utils/helpers';
import InvoicingDetailsDialog from 'components/InvoicingDetailsDialog';
import { signIn } from 'features/authSlice';
import { saveAccessToken } from 'utils/localStorage';
import Settings from './Settings';
import SettingsIcon from 'public/icons/settings.svg';
import NoAvatarImage from 'public/images/no-avatar.svg';
import DollarIcon from 'public/icons/dollar-green.svg';
import BillingIcon from 'public/icons/billing.svg';
import FilterIcon from 'public/icons/filter-main.svg';
import CogIcon from 'public/icons/cog.svg';
import GigsIcon from 'public/icons/loggedInFooter/gigs-purple-thick.svg';
import MessageIcon from 'public/icons/msg-window.svg';
import HandshakeIcon from 'public/icons/handshake-thick.svg';
import CrownIcon from 'public/icons/crown/crown-purple.svg';
import UserIcon from 'public/icons/user.svg';
import ChevronRightIcon from 'public/icons/chevron-right.svg';
import TransitionDialog from 'components/TransitionDialog';
import AffiliateDialog from 'components/AffiliateDialog';
import TwoFactorSettingsDialog from './TwoFactorSettingsDialog';
import EditAdvertiserProfile from 'components/advertiserProfile/EditAdvertiserProfile';
import ExternalLink from 'components/ExternalLink';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: '-4px',
    left: '-2px',
    width: 18,
    height: 18,
    zIndex: 2,
  },

  label: {
    padding: 0,
  },

  content: {
    margin: 0,
    fontFamily: 'Avenir Heavy',
    fontSize: 16,
  },

  expanded: {
    margin: '0px !important',
    minHeight: '50px !important',
    alignItems: 'center',
  },

  expandIcon: {
    margin: 0,
    padding: 0,
    transform: 'rotate(0deg)',

    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },

  paper: {
    boxShadow: 'none',
    margin: '0 !important',

    '&::before': {
      display: 'none',
    },
  },
});

const avatarFullSize = { width: '100%', height: '100%' };

const AdvertiserSidebar = ({ handleLogout, handleClose, open }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const currentUser = useSelector(state => state.auth.currentUser);
  const dispatch = useDispatch();
  const switchRef = useRef();
  const settingsRef = useRef();
  const classes = useStyles();

  const switchAccount = id => {
    postRequest({
      endpoint: `users/${id}/switch`,
      successMessage: 'Successfully switched account',
    })
      .then(response => {
        const token = getTokenFromResponse(response);

        if (token) saveAccessToken(token);

        dispatch(signIn(response.data));
        setCrispUser(response.data);
        identifyFullStoryUser(response.data);
        handleClose();

        window.location.replace('/dashboard');
      })
      .catch(() => {});
  };

  const handleCloseMenu = (event, ref, handleClose) => {
    if (ref?.current && ref?.current.contains(event.target)) {
      return;
    }

    handleClose;
  };

  const connectedUser = currentUser.connected_user;

  return (
    <TransitionDialog open={open} handleClose={handleClose} styles={styles}>
      <div className={styles.menuContainer}>
        <div className={styles.profileInfo}>
          <Link href={`/c/${currentUser.username}`} passHref>
            <div onClick={() => handleClose()}>
              <Avatar className={styles.img} src={currentUser.avatar_url}>
                <NoAvatarImage style={avatarFullSize} />
              </Avatar>
            </div>
          </Link>

          <h3>{currentUser.display_name || currentUser.name}</h3>
        </div>

        {connectedUser ? (
          <>
            <div className={styles.profile} style={{ marginLeft: 55 }}>
              <div
                className={styles.profileImg}
                ref={switchRef}
                onClick={() => setOpenMenu(prevState => !prevState)}
              >
                <Avatar src={connectedUser.avatar_url}>
                  <NoAvatarImage style={avatarFullSize} />
                </Avatar>

                <Chip
                  classes={{ root: classes.root, label: classes.label }}
                  label="I"
                  color="primary"
                />
              </div>
            </div>

            <Popper open={openMenu} anchorEl={switchRef.current} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <ClickAwayListener
                    onClickAway={() => handleCloseMenu(switchRef, setOpenMenu(false))}
                  >
                    <MenuList autoFocusItem={openMenu} className={styles.menuUl}>
                      <MenuItem onClick={() => switchAccount(connectedUser.id)}>
                        Switch Account
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Grow>
              )}
            </Popper>
          </>
        ) : (
          <div
            onClick={() => setOpenSettings(prevState => !prevState)}
            ref={settingsRef}
            className={styles.profile}
          >
            <SettingsIcon
              className={clsx(styles.settingsIcon, 'hover-icon')}
              width={40}
              height={40}
            />

            <Popper open={openSettings} anchorEl={settingsRef.current} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <ClickAwayListener
                    onClickAway={() => handleCloseMenu(settingsRef, setOpenSettings(false))}
                  >
                    <MenuList
                      autoFocusItem={openSettings}
                      className={clsx(styles.settingsMenu, styles.menuUl)}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(openModal({ name: 'Edit Advertiser Profile' }));
                          setOpenSettings(true);
                        }}
                      >
                        Edit Profile
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Grow>
              )}
            </Popper>
          </div>
        )}

        <Divider />

        <div style={{ padding: '20px 0' }}>
          <Link href={`/c/${currentUser.username}`} passHref>
            <div
              className={styles.heading}
              style={{ minHeight: 50, padding: '0px 16px' }}
              onClick={handleClose}
            >
              <UserIcon /> <h3>My profile</h3>
            </div>
          </Link>

          <Link href={'/gigs/draft'} passHref>
            <div
              className={styles.heading}
              style={{
                minHeight: 50,
                padding: '0px 16px',
              }}
              onClick={handleClose}
            >
              <GigsIcon />
              <h3>Gig Drafts</h3>
            </div>
          </Link>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <FilterIcon /> <h3>My account</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <MenuItem onClick={() => dispatch(openModal({ name: 'Settings' }))}>
                My account info
              </MenuItem>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <BillingIcon /> <h3>Billing</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <Link href="/wallet" passHref>
                <MenuItem onClick={handleClose}>Wallet</MenuItem>
              </Link>

              <Link href="/payments" passHref>
                <MenuItem onClick={handleClose}>Payment history</MenuItem>
              </Link>

              <MenuItem onClick={() => dispatch(openModal({ name: 'Invoicing Details' }))}>
                Invoicing details
              </MenuItem>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <CogIcon /> <h3>Settings</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <MenuItem onClick={() => dispatch(openModal({ name: 'Two Factor Settings' }))}>
                Two factor authentication
              </MenuItem>

              {!currentUser.connected_user && (
                <Link href="/influencers/new" passHref>
                  <MenuItem onClick={handleClose}>Create an influencer account</MenuItem>
                </Link>
              )}
            </MenuList>
          </Accordion>

          <div className={styles.refItem}>
            <div className={styles.heading}>
              <MessageIcon />

              <ExternalLink
                preview="url"
                mobileUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                desktopUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                page="FAQ"
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: 'unset' }}
              >
                <h3>FAQ/Chat support</h3>
              </ExternalLink>
            </div>
          </div>

          <Link href={`/partners`} passHref>
            <div
              className={styles.heading}
              style={{ minHeight: 50, padding: '0px 16px' }}
              onClick={handleClose}
            >
              <HandshakeIcon /> <h3>Partners</h3>
            </div>
          </Link>

          <div className={styles.refItem}>
            <Link href="/leaderboard" passHref>
              <MenuItem onClick={handleClose}>
                <div className={styles.heading}>
                  <CrownIcon width={24} height={24} alt="Crown" />
                  <h3>Leaderboard</h3>
                </div>
              </MenuItem>
            </Link>
          </div>

          <div className={styles.refItem}>
            <MenuItem onClick={() => dispatch(openModal({ name: 'Affiliate Program' }))}>
              <div className={styles.heading}>
                <DollarIcon /> <h3>Refer friends</h3>
              </div>
            </MenuItem>
          </div>
        </div>

        <Divider />

        <MenuList className={clsx(styles.menuList, styles.bottomSection)}>
          <Link href="/terms" passHref>
            <MenuItem onClick={handleClose}>Terms</MenuItem>
          </Link>

          <div className={styles.straightLine} />

          <Link href="/privacy" passHref>
            <MenuItem onClick={handleClose}>Privacy policy</MenuItem>
          </Link>

          <div className={styles.straightLine} />

          <div onClick={handleLogout}>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </div>
        </MenuList>

        <Settings />
        <AffiliateDialog />
        <InvoicingDetailsDialog />
        <TwoFactorSettingsDialog />
        <EditAdvertiserProfile />
      </div>
    </TransitionDialog>
  );
};

AdvertiserSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default AdvertiserSidebar;
