import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  Button,
  Avatar,
  InputAdornment,
  CircularProgress,
  TextareaAutosize,
  IconButton,
} from '@material-ui/core';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import clsx from 'clsx';

import { normalizeInitialValues, urlRegex } from 'utils/helpers';
import { updateCurrentUser } from 'features/authSlice';
import { getRequest, putRequest } from 'utils/api';
import { closeModal, openModal } from 'features/utilsSlice';
import { setAdvertiserProfile } from 'features/advertiserProfileSlice';
import compressImage from 'utils/compressImage';
import useModalOpen from 'hooks/useModalOpen';
import User from 'public/icons/form/user.svg';
import EditIcon from 'public/icons/edit.svg';
import AddIcon from 'public/icons/add-white.svg';
import LocationAutocomplete from 'components/LocationAutocomplete';
import TransitionDialog from 'components/TransitionDialog';
import CropDialog from 'components/CropDialog';
import styles from 'styles/components/ProfileForm.module.scss';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const defaultRequired = 'is required.';
const background = { backgroundColor: 'transparent' };
const marginTop = { marginTop: 20 };
const compressOptions = { quality: 0.8, maxWidth: 750, maxHeight: 750 };
const negativeMarginTop = { marginTop: -5 };

const schema = yup.object({
  username: yup
    .string()
    .matches(/^[a-zA-Z0-9_.-]*$/, 'is invalid (no spaces or special characters allowed).')
    .required(defaultRequired),
  display_name: yup.string().required(defaultRequired),
  bio: yup.string().required(defaultRequired),
  avatar: yup.mixed().when('avatar_url', avatarUrl => {
    if (avatarUrl) {
      return yup.mixed().nullable();
    }

    return yup
      .mixed()
      .test('fileSize', 'File size is too large', value =>
        value ? value.size <= 10 * 1024 * 1024 : true
      )
      .test('fileType', 'Unsupported File Format', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      )
      .required(defaultRequired);
  }),
  location: yup.string().required(defaultRequired),
  networks: yup.object().shape({
    instagram_url: yup
      .string()
      .matches(urlRegex, 'Please enter a valid URL.')
      .nullable(),
    website_url: yup
      .string()
      .matches(urlRegex, 'Please enter a valid URL.')
      .nullable(),
    tiktok_url: yup
      .string()
      .matches(urlRegex, 'Please enter a valid URL.')
      .nullable(),
    twitter_url: yup
      .string()
      .matches(urlRegex, 'Please enter a valid URL.')
      .nullable(),
    facebook_url: yup
      .string()
      .matches(urlRegex, 'Please enter a valid URL.')
      .nullable(),
  }),
});

const initialValues = {
  username: '',
  bio: '',
  display_name: '',
  avatar: '',
  location: '',
  networks: {
    instagram: '',
    tiktok: '',
    twitter: '',
    website: '',
    facebook: '',
  },
};

const EditProfileDialog = ({ children, handleClose }) => {
  const open = useModalOpen('Edit Advertiser Profile');

  return (
    <TransitionDialog open={open} styles={styles} handleClose={handleClose} noCloseIcon>
      {children}
    </TransitionDialog>
  );
};

const useStyles = makeStyles({
  large: {
    width: 108,
    height: 108,
  },

  root: {
    marginTop: 10,
    marginBottom: 7,
  },
});

const EditAdvertiserProfile = () => {
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(state => state.auth.currentUser);
  const fileRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const profile = useSelector(state => state.advertiserProfile.profile);
  const open = useModalOpen('Edit Advertiser Profile');

  const handleClose = () => {
    if (preview) setPreview(null);
    dispatch(closeModal('Edit Advertiser Profile'));
  };
  const handleFileClick = () => fileRef.current.click();

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    const data = new FormData(); // eslint-disable-line no-undef

    const keys = Object.keys(
      pick(values, [
        'username',
        'display_name',
        'avatar',
        'bio',
        'location',
        'location_latitude',
        'location_longitude',
      ])
    );

    keys.forEach(key => {
      if (values[key]) data.set(`user[${key}]`, values[key]);
    });

    Object.keys(values.networks).forEach(key => {
      data.set(`user[networks][${key}]`, values.networks[key]);
    });

    putRequest({
      endpoint: `users/${profile.id}`,
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      successMessage: `Successfully updated profile.`,
      ...actions,
    })
      .then(response => {
        setIsLoading(false);

        dispatch(
          updateCurrentUser({
            ...response.data,
            bio: values.bio,
          })
        );

        dispatch(
          setAdvertiserProfile({
            ...profile,
            ...response.data,
            bio: values.bio,
          })
        );

        handleClose();
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!currentUser.id || !currentUser.username) return;

    getRequest({
      endpoint: `users/${currentUser.username}/profile`,
    })
      .then(response => dispatch(setAdvertiserProfile(response)))
      .catch(() => {});
  }, [currentUser?.username, currentUser?.id, dispatch]);

  return (
    <EditProfileDialog handleClose={handleClose}>
      {open && currentUser && (
        <div className={styles.container}>
          <div className={styles.gallery}>
            <div className={styles.shadow} />
          </div>

          <Formik
            validationSchema={schema}
            initialValues={normalizeInitialValues({
              ...initialValues,
              ...profile,
            })}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmiting, values, setFieldValue, setFieldTouched, touched, errors }) => (
              <Form className={styles.form}>
                <div className={styles.avatarField}>
                  <>
                    <div onClick={handleFileClick} className={styles.avatar}>
                      <Avatar
                        src={preview || profile?.avatar_url}
                        className={classes.large}
                        alt="Avatar"
                      >
                        ''
                      </Avatar>

                      {preview || profile?.avatar_url ? (
                        <IconButton className="icon-btn-hover-dark">
                          <EditIcon width={24} height={24} alt="Edit" />
                        </IconButton>
                      ) : (
                        <IconButton className="icon-btn-hover-dark">
                          <AddIcon width={24} height={24} alt="Edit" />
                        </IconButton>
                      )}
                    </div>

                    <input
                      ref={fileRef}
                      type="file"
                      name="avatar"
                      onChange={e => {
                        if (preview) URL.revokeObjectURL(preview);

                        const file = e.target.files[0];

                        if (!file) return;

                        compressImage(file, compressOptions).then(compressedFile => {
                          setFieldValue('avatar', compressedFile);
                          setPreview(URL.createObjectURL(compressedFile));
                          dispatch(openModal({ name: 'Crop' }));
                        });
                      }}
                      accept="image/jpg, image/jpeg, image/png"
                    />
                  </>

                  <span className={styles.avatarErrors}>{get(errors, 'avatar')}</span>
                </div>
                <h3>Public information</h3>

                <InputLabel>Username</InputLabel>

                <Field
                  component={TextField}
                  name="username"
                  type="text"
                  placeholder="Username"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputLabel>Display Name</InputLabel>

                <Field
                  component={TextField}
                  name="display_name"
                  type="text"
                  placeholder="Display Name"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <User width={14} />
                      </InputAdornment>
                    ),
                  }}
                />

                <LocationAutocomplete
                  defaultValue={values.location}
                  handleChange={({ location, lat, lng }) => {
                    setFieldValue('location', location);
                    setFieldValue('location_latitude', lat);
                    setFieldValue('location_longitude', lng);
                  }}
                  error={Boolean(errors.location) && touched.location}
                  setFieldTouched={() => setFieldTouched('location', true)}
                  requiredText
                />

                <InputLabel>
                  Bio <span className="fw-n">(required)</span>
                </InputLabel>

                <TextareaAutosize
                  value={values.bio}
                  onChange={e => setFieldValue('bio', e.target.value)}
                  onBlur={() => setFieldTouched('bio', true)}
                  minRows={4}
                  maxRows={4}
                  maxLength={150}
                  placeholder="Write something about yourself..."
                  className={clsx({ ['error-border']: errors.bio && touched.bio })}
                  style={background}
                />

                <ErrorMessage
                  component="span"
                  name="bio"
                  className="error-msg"
                  style={negativeMarginTop}
                />

                <InputLabel style={marginTop}>Networks</InputLabel>

                <Field
                  component={TextField}
                  value={values?.networks?.instagram_url}
                  onChange={e => setFieldValue('networks.instagram_url', e.target.value)}
                  error={!!errors?.networks?.instagram_url}
                  name="instagram"
                  type="text"
                  placeholder="Instagram url"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          src="/icons/network/active/instagram.svg"
                          width={30}
                          height={30}
                          alt="Instagram"
                          unoptimized
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {get(errors, 'networks.instagram_url', '') && (
                  <div className="error-msg">must be a valid url.</div>
                )}

                <Field
                  component={TextField}
                  name="tiktok"
                  type="text"
                  value={values?.networks?.tiktok_url}
                  onChange={e => setFieldValue('networks.tiktok_url', e.target.value)}
                  error={!!errors?.networks?.tiktok_url}
                  placeholder="TikTok url"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          src="/icons/network/active/tiktok.svg"
                          width={30}
                          height={30}
                          alt="TikTok"
                          unoptimized
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {get(errors, 'networks.tiktok_url', '') && (
                  <div className="error-msg">must be a valid url.</div>
                )}

                <Field
                  component={TextField}
                  name="twitter"
                  type="text"
                  value={values?.networks?.twitter_url}
                  onChange={e => setFieldValue('networks.twitter_url', e.target.value)}
                  error={!!errors?.networks?.twitter_url}
                  placeholder="Twitter url"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          src="/icons/network/twitter.svg"
                          width={30}
                          height={30}
                          alt="Twitter"
                          unoptimized
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {get(errors, 'networks.twitter_url') && (
                  <div className="error-msg">must be a valid url.</div>
                )}

                <Field
                  component={TextField}
                  name="website"
                  type="text"
                  value={values?.networks?.website_url}
                  onChange={e => setFieldValue('networks.website_url', e.target.value)}
                  error={!!errors?.networks?.website_url}
                  placeholder="Website url"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          src="/icons/network/active/blog_website.svg"
                          width={30}
                          height={30}
                          alt="Website"
                          unoptimized
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {get(errors, 'networks.website_url') && (
                  <div className="error-msg">must be a valid url.</div>
                )}

                <Field
                  component={TextField}
                  name="website"
                  type="text"
                  value={values?.networks?.facebook_url}
                  onChange={e => setFieldValue('networks.facebook_url', e.target.value)}
                  error={!!errors?.networks?.facebook_url}
                  placeholder="Facebook url"
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          src="/icons/network/active/facebook.svg"
                          width={30}
                          height={30}
                          alt="Website"
                          unoptimized
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                {get(errors, 'networks.facebook_url') && (
                  <div className="error-msg">must be a valid url.</div>
                )}

                <div className={styles.buttons}>
                  <Button onClick={handleClose} color="primary" fullWidth>
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      isSubmiting ||
                      isLoading ||
                      !isEmpty(errors) ||
                      !values.bio ||
                      !values.location
                    }
                    fullWidth
                    type="submit"
                  >
                    {isLoading ? <CircularProgress color="primary" /> : 'Done'}
                  </Button>
                </div>

                <CropDialog
                  image={preview || values.avatar || profile?.avatar_url}
                  handleAvatarChange={avatar => {
                    if (preview) URL.revokeObjectURL(preview);
                    setFieldValue('avatar', avatar);
                    setPreview(URL.createObjectURL(avatar));
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </EditProfileDialog>
  );
};

export default EditAdvertiserProfile;
