import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    primary: { main: '#5b00db' },
    secondary: { main: '#323643' },
  },

  typography: { fontFamily: 'Avenir Roman' },

  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Avenir Heavy',
        fontSize: 14,
        borderRadius: 25,
        fontWeight: 900,
        letterSpacing: '-0.08px',
        height: 50,
        padding: '6px 25px',
        minWidth: 'fit-content',
        boxShadow: 'none !important',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      contained: {
        '&:hover': {
          boxShadow: 'none !important',
        },
      },

      outlinedPrimary: {
        borderColor: '#5b00db',

        '&:hover': {
          backgroundColor: '#5b00db !important',
          color: '#ffffff',
        },
      },

      textSecondary: {
        '&:hover': {
          color: '#5b00db',
          backgroundColor: 'transparent',
        },
      },

      textPrimary: {
        fontFamily: 'Avenir Heavy',
        color: '#888',
        letterSpacing: '0.94px',
      },
    },

    MuiGrid: {
      root: { padding: 0 },
      container: { minHeight: '100%' },

      item: {
        margin: '0 auto',
      },
    },

    MuiFormControl: {
      root: {
        width: '100%',
        height: 40,
        marginBottom: 20,
      },
    },

    MuiFormControlLabel: {
      labelPlacementStart: {
        marginRight: 0,
      },
    },

    MuiOutlinedInput: {
      root: {
        height: 40,
        letterSpacing: -0.09,
        borderRadius: 20,
        borderColor: '#c8ccd9',
        color: '#323643',
      },

      input: {
        padding: '0 20px',
        height: 40,
      },
    },

    MuiFormHelperText: {
      root: {
        marginTop: 0,
      },
    },

    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !Important',
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: '#c8ccd9',
      },
    },

    MuiStepper: {
      root: {
        padding: '24px 0',
      },
    },

    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 5,

        '&:last-child': {
          paddingRight: 0,
        },

        '&:first-child': {
          paddingRight: 0,
        },
      },
    },

    MuiDialog: {
      paperWidthSm: {
        width: 600,
      },
    },

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
      },
    },

    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
      },
    },

    MuiNativeSelect: {
      select: {
        color: '#323643',
        paddingTop: 10,

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },

      icon: {
        top: 11,
        filter:
          'invert(13%) sepia(92%) saturate(6151%) hue-rotate(268deg) brightness(79%) contrast(125%)',
      },
    },

    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: 14,
        marginBottom: 5,
      },
    },

    MuiIconButton: {
      edgeStart: {
        marginLeft: '-3px',
      },
    },

    MuiSlider: {
      rail: {
        height: 6,
        borderRadius: 5,
      },

      thumb: {
        width: 28,
        height: 28,
        marginTop: '-11px',
        boxShadow: ' 0 0 16px 0 rgba(0, 0, 0, 0.21)',
        border: 'solid 1px #d1d1d1',
        backgroundColor: '#fff',
        marginLeft: '-11px',

        '&$::after': {
          display: 'none',
        },
      },

      track: {
        height: 6,
        borderRadius: 5,
      },
    },

    MuiChip: {
      label: {
        fontFamily: 'HelveticaNeue-Medium',
        fontSize: 14,
        fontWeight: 500,
      },

      deletableColorSecondary: {
        '&:focus': {
          backgroundColor: '#323643',
        },
      },

      outlinedSecondary: {
        color: '#323643',
        opacity: '0.8',
        border: '2px solid #323643',
      },

      deleteIcon: {
        width: 8,
        height: 8,
        marginTop: 1,
        marginRight: 10,
      },

      clickable: {
        border: '2px solid transparent',

        '&:focus': {
          backgroundColor: 'rgba(91, 0, 219, 0.1)',
        },
      },
    },

    MuiMenuItem: {
      root: {
        padding: 0,
        minHeight: '100%',

        '&:hover': {
          backgroundColor: 'transparent',
          color: '#5b00db',
        },
      },
    },

    MuiAlert: {
      root: {
        width: '100%',
        minHeight: '150px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 10,
      },

      message: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 16,
      },

      standardError: {
        color: 'rgb(97, 26, 21)',
        backgroundColor: 'rgb(253, 236, 234)',
        borderRadius: 0,
        opacity: 1,
      },

      standardInfo: {
        color: '#323643',
        opacity: '0.6',
        backgroundColor: '#ebedf2',
      },
    },

    MuiTypography: {
      body1: {
        fontSize: 14,
      },
    },

    MuiFormLabel: {
      root: {
        fontSize: 16,
        fontFamily: 'Avenir Heavy',
        lineHeight: 1.25,
        opacity: 0.9,
        marginBottom: 10,
        color: '#323643',
      },

      asterisk: {
        color: '#5b00db',
        '&$error': {
          color: '#5b00db',
        },
      },
    },

    MuiTimeline: {
      root: {
        padding: '20px 0 0 15px',
      },
    },

    MuiTimelineItem: {
      root: {
        minHeight: 40,
      },

      missingOppositeContent: {
        '&:before': { display: 'none' },
      },
    },

    MuiTimelineDot: {
      defaultGrey: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 50,
        fontFamily: 'Avenir Heavy',
        backgroundColor: '#f5f0ff',
        boxShadow: 'none',
        margin: 0,
      },
    },

    MuiTimelineConnector: {
      root: {
        backgroundColor: '#a377fe',
      },
    },

    MuiTimelineSeparator: {
      root: {
        zIndex: 1,
      },
    },

    MuiTimelineOppositeContent: {
      root: {
        display: 'none',
      },
    },

    MuiTimelineContent: {
      root: {
        padding: '0px 0px 0px 15px',
      },
    },

    PrivateTabIndicator: {
      root: {
        height: 3,
      },
    },

    MuiRating: {
      label: {
        marginRight: 10,
      },
    },

    MuiLinearProgress: {
      colorSecondary: {
        width: '40%',
        margin: '0 auto',
        top: '50%',
        transform: 'translateY(-50%)',
        height: 3,
        backgroundColor: 'rgba(200, 204, 217, 0.2)',
      },

      barColorSecondary: {
        backgroundColor: '#fff',
      },
    },

    MuiCircularProgress: {
      colorPrimary: {
        display: 'flex',
        width: '100% !important',
        justifyContent: 'center',
        margin: 0,
      },

      colorSecondary: {
        display: 'flex',
        width: '100% !important',
        justifyContent: 'center',
        margin: 0,
      },

      svg: {
        width: 40,
      },
    },

    MuiAccordionSummary: {
      root: {
        minHeight: 50,
      },
    },

    MuiAutocomplete: {
      clearIndicator: {
        display: 'none',
      },
    },
  }, // end of overrides
});

export default theme;
