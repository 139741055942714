import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

import { closeModal } from 'features/utilsSlice';
import useModalOpen from 'hooks/useModalOpen';
import TransitionDialog from './TransitionDialog';
import styles from 'styles/components/CenteredDialog.module.scss';

const useStyles = makeStyles(theme => ({
  paper: {
    height: 'fit-content',
    width: '90%',
    marginBottom: 'auto',
    top: '15%',
    borderRadius: 10,

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  paperFullScreenDialog: {
    width: '100%',
  },
}));

const titleStyle = { fontSize: 18, marginBottom: 40 };
const padding = { padding: '20px 30px', overflow: 'hidden' };

const ConfirmationDialog = ({
  title,
  handleApprove,
  handleReject,
  disabled,
  rejectButtonText,
  approveButtonText,
  subtitle,
  setOpenConfirmation,
}) => {
  const open = useModalOpen('Confirmation');
  const dispatch = useDispatch();
  const classes = useStyles();

  const { action, rejectTitle = '' } = useSelector(state => state.utils.modalData['Confirmation']);

  const getHandleSubmit = () => {
    if (action === 'approve') {
      return handleApprove();
    }

    return handleReject();
  };

  const handleClose = () => {
    if (typeof setOpenConfirmation === 'function') setOpenConfirmation();
    dispatch(closeModal('Confirmation'));
  };

  return (
    <TransitionDialog
      open={open}
      handleClose={handleClose}
      paperProps={{ className: classes.paper }}
      dialogStyles={classes.paperFullScreenDialog}
      direction="up"
      desktopDirection="up"
      noCloseIcon
    >
      <div className={styles.container} style={padding}>
        <h3 style={titleStyle}>{rejectTitle ? rejectTitle : title}</h3>

        {subtitle && <p style={{ margin: '-20px 0 40px', textAlign: 'center' }}>{subtitle}</p>}

        <div className={styles.buttons}>
          <Button color="primary" onClick={handleClose} fullWidth>
            {rejectButtonText}
          </Button>

          <Button
            onClick={getHandleSubmit}
            variant="contained"
            color="primary"
            disabled={disabled}
            fullWidth
          >
            {disabled ? <CircularProgress color="primary" /> : approveButtonText}
          </Button>
        </div>
      </div>
    </TransitionDialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  fhandleApprove: PropTypes.func,
  handleReject: PropTypes.func,
  disabled: PropTypes.bool,
  approveButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  subtitle: PropTypes.string,
  setOpenConfirmation: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  handleReject: () => {},
  handleApprove: () => {},
  setOpenConfirmation: null,
  disabled: false,
  approveButtonText: 'Yes',
  rejectButtonText: 'No',
  title: '',
  subtitle: '',
};

export default ConfirmationDialog;
