import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import {
  MenuItem,
  MenuList,
  Avatar,
  Divider,
  Chip,
  Popper,
  Grow,
  ClickAwayListener,
  Accordion,
  AccordionSummary,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { openModal } from 'features/utilsSlice';
import { postRequest } from 'utils/api';
import { signIn, updateSelectedProfile } from 'features/authSlice';
import {
  getTokenFromResponse,
  identifyFullStoryUser,
  normalizePrimaryInterest,
  setCrispUser,
} from 'utils/helpers';
import { setStep } from 'features/influencerSetupSlice';
import { saveAccessToken } from 'utils/localStorage';
import useIsInfluencer from 'hooks/useIsInfluencer';
import useIsBuyer from 'hooks/useIsBuyer';
import AddIcon from 'public/icons/add-main.svg';
import AddIconCircle from 'public/icons/add-circle-dark.svg';
import FilterIcon from 'public/icons/filter-main.svg';
import BillingIcon from 'public/icons/billing.svg';
import SettingsIcon from 'public/icons/cog.svg';
import MessageIcon from 'public/icons/msg-window.svg';
import useCurrentProfile from 'hooks/useCurrentProfile';
import NoAvatarImage from 'public/images/no-avatar.svg';
import DollarIcon from 'public/icons/dollar-green.svg';
import UserIcon from 'public/icons/user.svg';
import ChevronRightIcon from 'public/icons/chevron-right.svg';
import ChevronRightGrey from 'public/icons/chevron-right-grey.svg';
import HandshakeIcon from 'public/icons/handshake-thick.svg';
import EditProfile from '../EditProfile';
import GigsIcon from 'public/icons/loggedInFooter/gigs-purple-thick.svg';
import CrownIcon from 'public/icons/crown/crown-purple.svg';
import ArrowGrowIcon from 'public/icons/arrow-grow-purple.svg';
import Settings from './Settings';
import AddProfile from './AddProfile';
import GigSettingsDialog from './GigSettingsDialog';
import TransitionDialog from 'components/TransitionDialog';
import InventoryEditDialog from 'components/InventoryEditDialog';
import Badge from 'components/Badge';
import AffiliateDialog from 'components/AffiliateDialog';
import TwoFactorSettingsDialog from 'components/sidebar/TwoFactorSettingsDialog';
import InvoicingDetailsDialog from 'components/InvoicingDetailsDialog';
import ExternalLink from 'components/ExternalLink';
import SwitchPrimaryInterestPopper from 'components/SwitchPrimaryInterestPopper';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: -5,
    width: 18,
    height: 18,
    zIndex: 2,

    '&&:hover': {
      cursor: 'pointer',
    },
  },

  label: {
    padding: 0,
  },

  content: {
    margin: 0,
    fontFamily: 'Avenir Heavy',
    fontSize: 16,
  },

  expanded: {
    margin: '0px !important',
    minHeight: '50px !important',
    alignItems: 'center',
  },

  expandIcon: {
    margin: 0,
    padding: 0,
    transform: 'rotate(0deg)',

    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },

  paper: {
    boxShadow: 'none',
    margin: '0 !important',

    '&::before': {
      display: 'none',
    },
  },
});

const zIndex = { zIndex: 2 };
const avatarFullSize = { width: '100%', height: '100%' };

const InfluencerMobileSidebar = ({ open, handleClose, handleLogout }) => {
  const [openMenu, setOpenMenu] = useState({ profile: false, switch: false });
  const [openInterestMenu, setOpenInterestMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const profiles = useSelector(state => state.auth.currentUser.profiles) || [];
  const currentUser = useSelector(state => state.auth.currentUser);
  const isInfluencer = useIsInfluencer();
  const currentProfile = useCurrentProfile();
  const dispatch = useDispatch();
  const classes = useStyles();
  const switchRef = useRef();
  const anchorRef = useRef();
  const isAgency = currentUser?.full_management_agency;
  const primaryInterest = currentUser.primary_interest;
  const normalizedInterest = normalizePrimaryInterest(primaryInterest);
  const showPrimaryInterestSwitch = isInfluencer && !currentUser?.full_management_agency;

  const isBuyer = useIsBuyer();

  const handleCloseMenu = (event, ref, handleClose) => {
    if (ref?.current && ref?.current.contains(event.target)) {
      return;
    }

    handleClose;
  };

  const handleToggle = () => setOpenInterestMenu(prevOpen => !prevOpen);

  const selectProfile = id => {
    postRequest({
      endpoint: `profiles/${id}/select`,
    })
      .then(() => {
        dispatch(updateSelectedProfile(id));
        window.location.replace('/dashboard');
      })
      .catch(() => {});
  };

  const switchAccount = id => {
    postRequest({
      endpoint: `users/${id}/switch`,
      successMessage: 'Successfully switched account',
    })
      .then(response => {
        const token = getTokenFromResponse(response);

        if (token) saveAccessToken(token);

        dispatch(signIn(response.data));
        setCrispUser(response.data);
        identifyFullStoryUser(response.data);
        handleClose();

        window.location.replace('/dashboard');
      })
      .catch(() => {});
  };

  const connectedUser = currentUser.connected_user;

  return (
    <TransitionDialog open={open} handleClose={handleClose} styles={styles}>
      <div>
        <div className={styles.profilesContainer}>
          <div className={styles.profiles}>
            {connectedUser && (
              <>
                <div className={styles.profile}>
                  <div
                    ref={switchRef}
                    onClick={() => setOpenMenu(prevState => ({ ...prevState, switch: true }))}
                    className={styles.profileImg}
                  >
                    <Avatar src={connectedUser.avatar_url}>
                      <NoAvatarImage style={avatarFullSize} />
                    </Avatar>

                    <Chip
                      classes={{ root: classes.root, label: classes.label }}
                      label="A"
                      color="primary"
                    />
                  </div>
                </div>

                <Popper
                  open={openMenu.switch}
                  anchorEl={switchRef.current}
                  transition
                  disablePortal
                  style={zIndex}
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <ClickAwayListener
                        onClickAway={() =>
                          handleCloseMenu(
                            switchRef,
                            setOpenMenu(prevState => ({ ...prevState, switch: false }))
                          )
                        }
                      >
                        <MenuList autoFocusItem={openMenu.switch} className={styles.menuUl}>
                          <MenuItem onClick={() => switchAccount(connectedUser.id)}>
                            Switch Account
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Grow>
                  )}
                </Popper>
              </>
            )}

            {!isAgency &&
              profiles
                .filter(p => p.id !== currentProfile?.id)
                .map(profile => (
                  <div className={styles.profile} key={profile.id}>
                    <div
                      onClick={e => setAnchorEl({ [profile.id]: e.currentTarget })}
                      className={styles.profileImg}
                    >
                      <Badge
                        content={profile.notifications}
                        badgeStyles={{ border: '2px solid #fff', padding: '0 3px' }}
                      >
                        <Avatar src={profile.avatar_url}>
                          <NoAvatarImage style={avatarFullSize} />
                        </Avatar>
                      </Badge>
                    </div>

                    <Popper
                      open={anchorEl && Boolean(anchorEl[profile.id])}
                      anchorEl={anchorEl && anchorEl[profile.id]}
                      transition
                      disablePortal
                      className={styles.popper}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <MenuList variant="menu" className={styles.menuUl}>
                              <MenuItem
                                onClick={() => {
                                  selectProfile(profile.id);
                                  setAnchorEl(null);
                                }}
                              >
                                Switch Profile
                              </MenuItem>

                              <Divider />

                              <MenuItem
                                onClick={() => {
                                  dispatch(openModal({ name: 'Edit Profile', data: { profile } }));
                                  setAnchorEl(null);
                                }}
                              >
                                Edit Profile
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                ))}
          </div>

          <div
            onClick={() => dispatch(openModal({ name: 'Add Profile' }))}
            style={{ marginBottom: 'auto' }}
          >
            {profiles.length > 1 ? (
              <div className={styles.iconContainer}>
                <AddIcon />
              </div>
            ) : (
              <Button className={styles.button} endIcon={<AddIconCircle />}>
                Add account
              </Button>
            )}
          </div>
        </div>
      </div>

      <div
        className={styles.menuContainer}
        style={currentUser.profiles?.length > 1 ? { paddingTop: 40 } : {}}
      >
        <div className={styles.profileInfo} style={{ margin: 0 }}>
          <Link href={`/u/${currentProfile?.username}`} passHref>
            <div onClick={() => handleClose()}>
              <Avatar className={styles.img} src={currentProfile?.avatar_url}>
                <NoAvatarImage style={avatarFullSize} />
              </Avatar>
            </div>
          </Link>

          <div className={styles.interestSwitch}>
            <h3 style={{ margin: 0 }}>{currentProfile?.display_name}</h3>

            {showPrimaryInterestSwitch && (
              <div onClick={handleToggle} ref={anchorRef}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 style={{ marginRight: 5 }}>{normalizedInterest}</h4>

                  <ChevronRightGrey className={styles.expandIcon} />
                </div>

                <SwitchPrimaryInterestPopper
                  open={openInterestMenu}
                  setOpen={setOpenInterestMenu}
                  anchorRef={anchorRef}
                />
              </div>
            )}
          </div>
        </div>

        <Divider />

        <div style={{ padding: '20px 0' }}>
          <Link href={`/u/${currentProfile?.username}`} passHref>
            <div
              className={styles.heading}
              style={{ minHeight: 50, padding: '0px 16px' }}
              onClick={handleClose}
            >
              <UserIcon /> <h3>My profile and rate card pricing</h3>
            </div>
          </Link>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <FilterIcon /> <h3>My account</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              {/* <Link href={`/u/${currentProfile?.username}`} passHref>
                <MenuItem onClick={handleClose}>My public info</MenuItem>
              </Link> */}

              <MenuItem
                onClick={() => {
                  dispatch(openModal({ name: 'Settings' }));
                  handleClose;
                }}
              >
                My account info
              </MenuItem>

              <MenuItem
                onClick={() => {
                  dispatch(openModal({ name: 'Edit Profile', data: { profile: currentProfile } }));
                  handleClose;
                }}
              >
                My profile info
              </MenuItem>

              {currentProfile?.completed ? (
                [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      dispatch(setStep(1));
                      dispatch(openModal({ name: 'Inventory Edit', data: { step: 'Networks' } }));
                    }}
                  >
                    My social networks
                  </MenuItem>,

                  currentProfile.adult && (
                    <MenuItem
                      key={2}
                      onClick={() => {
                        dispatch(setStep(3));
                        dispatch(openModal({ name: 'Inventory Edit', data: { step: 'Traits' } }));
                      }}
                    >
                      My traits
                    </MenuItem>
                  ),

                  // In case we return it

                  // <MenuItem
                  //   key={2}
                  //   onClick={() =>
                  //     dispatch(openModal({ name: 'Inventory Edit', data: { step: 'Category' } }))
                  //   }
                  // >
                  //   My categories
                  // </MenuItem>,

                  // <MenuItem
                  //   key={3}
                  //   onClick={() =>
                  //     dispatch(openModal({ name: 'Inventory Edit', data: { step: 'Audience' } }))
                  //   }
                  // >
                  //   My audience interests
                  // </MenuItem>,

                  // <MenuItem
                  //   key={4}
                  //   onClick={() => {
                  //     dispatch(openModal({ name: 'Inventory Edit', data: { step: 'Experience' } }));
                  //   }}
                  // >
                  //   My traits
                  // </MenuItem>,
                ]
              ) : (
                <Link href="/inventory" passHref>
                  <MenuItem onClick={handleClose}>Sellable networks & inventory</MenuItem>
                </Link>
              )}

              <Link href={`/${currentProfile?.username}/edit`} passHref>
                <div onClick={handleClose}>
                  <MenuItem>My links</MenuItem>
                </div>
              </Link>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <BillingIcon /> <h3>Billing</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList} autoFocusItem={open}>
              <Link href="/wallet" passHref>
                <MenuItem onClick={handleClose}>Wallet</MenuItem>
              </Link>

              <Link href="/discount-codes" passHref>
                <MenuItem onClick={handleClose}>Discount codes</MenuItem>
              </Link>

              <Link href="/payments" passHref>
                <MenuItem onClick={handleClose}>Payment history</MenuItem>
              </Link>

              <MenuItem onClick={() => dispatch(openModal({ name: 'Invoicing Details' }))}>
                Invoicing details
              </MenuItem>
            </MenuList>
          </Accordion>

          <Accordion className={classes.paper}>
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
                details: classes.details,
              }}
              expandIcon={<ChevronRightIcon width={16} height={16} />}
            >
              <div className={styles.heading}>
                <SettingsIcon /> <h3>Settings</h3>
              </div>
            </AccordionSummary>

            <MenuList className={styles.menuList}>
              <MenuItem onClick={() => dispatch(openModal({ name: 'Gig Settings' }))}>
                Gig settings
              </MenuItem>

              <MenuItem onClick={() => dispatch(openModal({ name: 'Two Factor Settings' }))}>
                Two factor authentication
              </MenuItem>

              {!currentUser.connected_user && (
                <Link href="/users/new" passHref>
                  <MenuItem onClick={handleClose}>Create an advertiser account</MenuItem>
                </Link>
              )}
            </MenuList>
          </Accordion>

          <div className={styles.refItem}>
            <div className={styles.heading}>
              <MessageIcon />

              <ExternalLink
                preview="url"
                mobileUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                desktopUrl="https://gigsocial.crisp.help/en-us/category/buying-on-gigsocial-7fa8h5/"
                page="FAQ"
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: 'unset' }}
              >
                <h3>FAQ/Chat support</h3>
              </ExternalLink>
            </div>
          </div>

          <Link href={`/partners`} passHref>
            <div
              className={styles.heading}
              style={{ minHeight: 50, padding: '0px 16px' }}
              onClick={handleClose}
            >
              <HandshakeIcon /> <h3>Partners</h3>
            </div>
          </Link>

          {isBuyer && (
            <div className={styles.refItem}>
              <Link href={'/gigs/draft'} passHref>
                <MenuItem onClick={handleClose}>
                  <div className={styles.heading}>
                    <GigsIcon />
                    <h3>Gig Drafts</h3>
                  </div>
                </MenuItem>
              </Link>
            </div>
          )}

          <div className={styles.refItem}>
            <Link href="/gigs/closed" passHref>
              <MenuItem onClick={handleClose}>
                <div className={styles.heading}>
                  <GigsIcon width={24} height={24} alt="Suitcase" />
                  <h3>Archived Gigs</h3>
                </div>
              </MenuItem>
            </Link>
          </div>

          <div className={styles.refItem}>
            <Link href="/leaderboard" passHref>
              <MenuItem onClick={handleClose}>
                <div className={styles.heading}>
                  <CrownIcon width={24} height={24} alt="Crown" />
                  <h3>Leaderboard</h3>
                </div>
              </MenuItem>
            </Link>
          </div>

          <div className={styles.refItem}>
            <Link href="/earnings" passHref>
              <MenuItem onClick={handleClose}>
                <div className={styles.heading}>
                  <ArrowGrowIcon width={24} height={24} alt="Arrow grow" />
                  <h3>Earnings</h3>
                </div>
              </MenuItem>
            </Link>
          </div>

          <div className={styles.refItem}>
            <MenuItem onClick={() => dispatch(openModal({ name: 'Affiliate Program' }))}>
              <div className={styles.heading}>
                <DollarIcon /> <h3>Refer friends</h3>
              </div>
            </MenuItem>
          </div>
        </div>

        <Divider />

        <MenuList className={clsx(styles.menuList, styles.bottomSection)}>
          <Link href="/terms" passHref>
            <MenuItem onClick={handleClose}>Terms</MenuItem>
          </Link>

          <div className={styles.straightLine} />

          <Link href="/privacy" passHref>
            <MenuItem onClick={handleClose}>Privacy policy</MenuItem>
          </Link>

          <div className={styles.straightLine} />

          <div onClick={handleLogout}>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </div>
        </MenuList>
      </div>

      <EditProfile />
      <AddProfile />
      <Settings />
      <GigSettingsDialog />
      <InventoryEditDialog />
      <AffiliateDialog />
      <TwoFactorSettingsDialog />
      <InvoicingDetailsDialog />
    </TransitionDialog>
  );
};

InfluencerMobileSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default InfluencerMobileSidebar;
